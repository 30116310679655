import React, { useState, useEffect } from 'react'
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import axiosInstance from '../axiosInstance/axiosInstance';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { AnimatePresence } from 'framer-motion';
import AddCountry from '../CountryDetails/AddCountry';
import AddUniversity from '../UniversityDetails/AddUniversity';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { useNavigate } from 'react-router-dom';
import UpdateCountry from '../CountryDetails/UpdateCountry';
import UpdateUniversity from '../UniversityDetails/UpdateUniversity';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const UniversityCenter = ({itemsPerPage, isAddCountryVisible, setIsAddCountryVisible, setIsAddUniversityVisible, isAddUniversityVisible, setUniversities, universities, fetchUniversities, prevPage, nextPage, setIsUpdateCountryVisible, isUpdateCountryVisible, isUpdateUniversityVisible, setIsUpdateUniversityVisible }) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [selectedTab, setSelectedTab] = useState(null);
    const [visibility, setVisibility] = useState({});
    const [selectedCountryId, setSelectedCountryId] = useState(null);
    const [selectedUniversityId, setSelectedUniversityId] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // For delete confirmation modal
    const [universityToDelete, setUniversityToDelete] = useState(null);
    const navigate = useNavigate();// Add this line


    const handleNext = () => {
        if (nextPage) {
            fetchUniversities(nextPage);
            setCurrentPage((prev) => prev + 1);
        }
    };

    // Handle "Previous" button click
    const handlePrevious = () => {
        if (prevPage) {
            fetchUniversities(prevPage);
            setCurrentPage((prev) => prev - 1);
        }
    };
    const toggleVisibility = async (index, event, universityId, currentVisibility) => {
        event.stopPropagation();
        const updatedVisibility = !currentVisibility;

        try {
            const updatedUniversities = [...universities];
            updatedUniversities[index] = { ...updatedUniversities[index], is_enabled: updatedVisibility };
            setUniversities(updatedUniversities);
            setVisibility(prev => ({ ...prev, [universityId]: updatedVisibility }));

            await axiosInstance.patch(`home/api/v1/university_detail/${universityId}/`, {
                is_enabled: updatedVisibility,
            });
        } catch (error) {
            console.error('Error toggling visibility:', error);

            // Revert the change if there was an error
            const revertedUniversities = [...universities];
            revertedUniversities[index] = { ...revertedUniversities[index], is_enabled: !updatedVisibility };
            setUniversities(revertedUniversities);
            setVisibility(prev => ({ ...prev, [universityId]: !updatedVisibility }));
        }
    };
    const handleUniversityClick = (universityId) => {
        axiosInstance.get(`home/api/v1/university_detail/${universityId}/`)
            .then(response => {
                const universityDetails = response.data;
                // Navigate to /UniversityCourse with universityId
                navigate(`/UniversityCourse`, { state: { universityDetails, universityId } });
            })
            .catch(error => {
                console.error('Error fetching university details:', error);
            });
    };
    const toggleUpdateCountryModal = (countryId, event) => {
        event.stopPropagation();
        setSelectedCountryId(countryId);
        setIsUpdateCountryVisible(prev => !prev);
    };
    const toggleUpdateUniversityModal = (universityId, event) => {
        event.stopPropagation();
        setSelectedUniversityId(universityId);
        setIsUpdateUniversityVisible(prev => !prev);
    };

    const deactivateUniversity = async (universityId, event) => {
        event.stopPropagation();
        try {
            await axiosInstance.patch(`home/api/v1/university_detail/${universityId}/`, {
                is_active: false  // Set is_active to false instead of deleting
            });
            setUniversities(prevUniversities =>
                prevUniversities.map(u =>
                    u.id === universityId ? { ...u, is_active: false } : u
                )
            );
            console.log(`University with ID ${universityId} has been deactivated.`);
            fetchUniversities();  // Optionally refetch the university list
        } catch (error) {
            console.error('Error deactivating university:', error);
        }
    };


    const handleDeleteUniversity = async () => {
        try {
            await axiosInstance.patch(`home/api/v1/university_detail/${universityToDelete}/`, {
                is_active: false  // Set is_active to false
            });
            setUniversities(prevUniversities =>
                prevUniversities.map(u =>
                    u.id === universityToDelete ? { ...u, is_active: false } : u
                )
            );
            console.log(`University with ID ${universityToDelete} has been deactivated.`);
            fetchUniversities();  // Optionally refetch the university list
            setIsDeleteModalOpen(false);  // Close the modal after deletion
        } catch (error) {
            console.error('Error deactivating university:', error);
        }
    };

    // Function to close delete confirmation modal
    const handleCloseDeleteModal = () => {
        setIsDeleteModalOpen(false);
    };
    const handleOpenDeleteModal = (universityId, event) => {
        event.stopPropagation();
        setUniversityToDelete(universityId);  // Set the university ID for deletion
        setIsDeleteModalOpen(true);           // Open the modal
    };



    return (
        <div >
            <div>
                <div className='flex flex-col gap-6 '>
                    <div className="grid grid-row-1 md:grid-row-1 lg:grid-row-2  w-full  ">
                    {universities && Array.isArray(universities) && universities.map((university, index) => (
                            <div key={university.id} className="bg-white py-2.5  border-2 cursor-pointer  "
                                onClick={() => handleUniversityClick(university.id)} >
                                <div className="flex flex-col justify-between px-2">
                                    <div>
                                        <div className="flex  justify-between items-center">
                                            <div className='flex gap-4'>
                                                <img src={university.logo_image} alt="University Logo" className="w-12 h-12 object-fit" onClick={(event) => toggleUpdateUniversityModal(university.id, event)} />
                                                <div>
                                                    <div className='flex items-center gap-2'>
                                                        <div className='text-[14px] pt-sans-bold '>{university.name}</div>
                                                        {university.is_norvel_recommended && (
                                                            <div className="pt-sans-bold text-[12px] bg-green-500 rounded-sm px-1 whitespace-nowrap">
                                                                Norvel Recommended
                                                            </div>
                                                        )}
                                                    </div>

                                                    

                                                    {/* <div className='flex gap-1 items-center'>
                                                        <div className='text-[12px]'>
                                                            Fees Starts at:
                                                            <span className='text-[10px] pt-sans-bold'>
                                                                {university.minimum_fee_per_year} Lakhs per Year*
                                                            </span>
                                                        </div>
                                                        Adding a slash between fees and language requirement
                                                        {university.language_requirements && university.language_requirements.length > 0 && (
                                                            <div className='text-[10px] mt-1 pt-sans-regular flex items-center'>
                                                                Map through the language requirements
                                                                <span className='pr-1'>/</span> 
                                                                {university.language_requirements.map((lang, idx) => (
                                                                    <span key={lang.id}>
                                                                        {lang.name}
                                                                        {idx < university.language_requirements.length - 1 ? "\u00A0/\u00A0" : ""}
                                                                    </span>
                                                                ))}
                                                            </div>
                                                        )}  
                                                        </div> */}


<div className='  flex  items-center gap-2' >
                                            <div className='text-[13px]'>{university.country? university.country.name + " : ": undefined } </div>
                                            <div className='text-[13px] pt-sans-bold  '>{university.location}</div>

                                        </div>  

                                                </div>
                                            </div>
                                            <div className='hidden md:block'>
                                                {/* <div className="flex gap-1 items-center " onClick={(event) => toggleUpdateCountryModal(university.country.id, event)}>
                                                    <div className='pt-sans-bold text-[13px]'>{university.country?.name}</div>
                                                    <img src={university.country?.

                                                        flag
                                                    } alt="University Logo" className="w-8 h-8 rounded-full" />

                                                </div> */}
                                                 <div className='flex gap-4 justify-end'>
                                        <div className='flex flex-row'>
                                            <div className='pr-4 text-[14px] pt-sans-bold text-[#356DDD]'>{university.is_assigned_to_agency? university.agency?.user_info?.first_name:null }</div> 
                                            
                                            <div onClick={(event) => toggleVisibility(index, event, university.id, university.is_enabled)}>
                                                {university.is_enabled ? (
                                                    <div className='text-[14px] pt-sans-bold flex justify-end cursor-pointer text-green-600'  ><img src="Eye.svg" alt="eye on icon" /></div>
                                                ) : (
                                                    <div className='text-[14px] pt-sans-bold flex justify-end cursor-pointer text-yellow-500' ><img src="Eyeoff.svg" alt="eye off icon" /></div>
                                                )}
                                            </div>

                                        </div>
                                        <div className='text-[14px] pt-sans-bold flex justify-end cursor-pointer text-red-500' onClick={(event) => handleOpenDeleteModal(university.id, event)}>Delete university</div>
                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class=" border border-dotted  border-gray-300      flex items-center justify-center "></div> */}

                                    <div className='flex justify-between'>
                                        {/* <div className='hidden md:block' >
                                            <div className='text-[12px]'>Location:</div>
                                            <div className='text-[12px] pt-sans-bold  '>{university.location}</div>

                                        </div> */}
                                        {/* <div class="border-dotted-b   flex items-center justify-center block md:hidden"></div> */}

                                        {/* <div className="flex">

                                            <div className='px-6 flex gap-2 items-center'>
                                                <div>
                                                    <img src="./scholarships.png" className='w-7 h-7 object-contain' />

                                                </div>
                                                <div>
                                                    <div className='text-[13px] pt-sans-regular'>Diploma</div>
                                                    <div className='pt-sans-bold text-[12px]'>{university.education_levels_count?.Diploma}</div>
                                                </div>
                                            </div>
                                            <div className='px-6 flex gap-2 items-center'>
                                                <div>
                                                    <img src="./bachelors.png" className='w-6 h-6 object-contain' />

                                                </div>
                                                <div>
                                                    <div className='text-[13px] pt-sans-regular'>Bachelors</div>
                                                    <div className='pt-sans-bold text-[12px]'>{university.education_levels_count?.Bachelors}</div>
                                                </div>
                                            </div>
                                            <div className='px-6 flex gap-2 items-center'>
                                                <div>
                                                    <img src="./masters.png" className='w-6 h-6 object-contain' />

                                                </div>
                                                <div>
                                                    <div className='text-[13px] pt-sans-regular'>Masters</div>
                                                    <div className='pt-sans-bold text-[12px]'>{university.education_levels_count?.Masters}</div>
                                                </div>
                                            </div>
                                            <div className='px-6 flex gap-2 items-center'>
                                                <div>
                                                    <img src="./type.png" className='w-6 h-6 object-contain' />

                                                </div>
                                                <div>
                                                    <div className='text-[13px] pt-sans-regular'>Type</div>
                                                    <div className='pt-sans-bold text-[12px]'>{university.university_type}</div>
                                                </div>
                                            </div>
                                            <div className='px-6 flex gap-2 items-center'>
                                                <div>
                                                    <img src="./rank.png" className='w-6 h-6 object-contain' />

                                                </div>
                                                <div>
                                                    <div className='text-[13px] pt-sans-regular'>Global Ranking</div>
                                                    <div className='pt-sans-bold text-[12px]'>{university.rank}</div>
                                                </div>
                                            </div>
                                        </div> */}


                                    </div>
                                    {/* <div class=" border border-dotted  border-gray-300      flex items-center justify-center "></div> */}
                                    {/* <div className='flex gap-4 justify-end'>
                                        <div>
                                            <div onClick={(event) => toggleVisibility(index, event, university.id, university.is_enabled)}>
                                                {university.is_enabled ? (
                                                    <div className='text-[12px] pt-sans-bold flex justify-end cursor-pointer text-green-600'  >Show University</div>
                                                ) : (
                                                    <div className='text-[12px] pt-sans-bold flex justify-end cursor-pointer text-yellow-500' >Hide University</div>
                                                )}
                                            </div>

                                        </div>
                                        <div className='text-[12px] pt-sans-bold flex justify-end cursor-pointer text-red-500' onClick={(event) => handleOpenDeleteModal(university.id, event)}>Delete university</div>
                                    </div> */}



                                </div>
                            </div>

                        ))}
                    </div>
                </div>
                {/* <div className="flex gap-2 py-6 items-center  justify-center ">

                    <button
                        className=" border border-gray-600  text-gray-700  rounded-sm pt-sans-bold"
                        onClick={handlePrevious}
                    >
                        <KeyboardDoubleArrowLeftIcon style={{ fontSize: '20px' }} />
                    </button>
                    <button
                        className=" border border-gray-600  text-gray-700  rounded-sm pt-sans-bold"
                        onClick={handlePrevious}
                    >
                        <KeyboardArrowLeftIcon style={{ fontSize: '20px' }} />
                    </button>

                    <div className=" text-[14px] bg-black px-4 py-0.5 text-white pt-sans-bold">{currentPage}</div>
                    {nextPage && (
                        <div>
                            <button
                                className="border border-gray-600 text-gray-700 rounded-sm pt-sans-bold"
                                onClick={handleNext}
                            >
                                <KeyboardArrowRightIcon style={{ fontSize: '20px' }} />
                            </button>
                        </div>
                    )}
                    {nextPage && (
                        <div>
                            <button
                                className="border border-gray-600 text-gray-700 rounded-sm pt-sans-bold"
                                onClick={handleNext}
                            >
                                <KeyboardDoubleArrowRightIcon style={{ fontSize: '20px' }} />
                            </button>
                        </div>
                    )}


                </div> */}


                <AnimatePresence>
                    {isAddCountryVisible && <AddCountry setIsAddCountryVisible={setIsAddCountryVisible} />}
                </AnimatePresence>
                <AnimatePresence>

                    {isAddUniversityVisible && (
                        <AddUniversity setIsAddUniversityVisible={setIsAddUniversityVisible} fetchUniversities={fetchUniversities} />
                    )}
                </AnimatePresence>
                <AnimatePresence>
                    {isUpdateCountryVisible && (
                        <UpdateCountry countryId={selectedCountryId} onClose={toggleUpdateCountryModal} setIsUpdateCountryVisible={setIsUpdateCountryVisible} fetchUniversities={fetchUniversities} />
                    )}
                </AnimatePresence>
                <AnimatePresence>
                    {isUpdateUniversityVisible && (
                        <UpdateUniversity currentPage={currentPage}itemsPerPage={itemsPerPage} universityId={selectedUniversityId} onClose={toggleUpdateCountryModal} setIsUpdateUniversityVisible={setIsUpdateUniversityVisible} fetchUniversities={fetchUniversities} />
                    )

                    }

                </AnimatePresence>



            </div>
            <Modal open={isDeleteModalOpen} onClose={handleCloseDeleteModal}>
                <Box
                    sx={{
                        width: 500,

                        p: 4,
                        margin: 'auto',
                        marginTop: '15%',
                        backgroundColor: 'white',
                        borderRadius: 2,
                        boxShadow: 24,
                    }}
                >
                    <div className='flex gap-1  ' >
                        <div><DeleteForeverIcon style={{ color: 'red' }} /></div>
                        <div className="inter-light pb-6 mt-1 ">Are you sure you want to delete this university?</div> </div>

                    {/* Reduced padding between the buttons and the content */}
                    <div className="flex justify-end gap-4 mt-4">
                        <button
                            onClick={handleCloseDeleteModal}
                            className="bg-black text-white font-bold py-1 px-8 text-[13px] rounded-sm inter-light"
                        >
                            Cancel 
                        </button>
                        <button
                            onClick={handleDeleteUniversity}
                            className="bg-black text-white font-bold py-1 px-8 text-[13px] rounded-sm inter-light"
                        >
                            Delete
                        </button>
                    </div>
                </Box>
            </Modal>

        </div>
    )
}

export default UniversityCenter