
import React, { useState, useEffect } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { AnimatePresence } from 'framer-motion';
import axiosInstance from '../axiosInstance/axiosInstance';
import AddCountry from './AddCountry';
import UpdateCountry from './UpdateCountry';
import { debounce } from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const CountryListingPage = () => {
    const [isAddCountryVisible, setIsAddCountryVisible] = useState(false);
    const [isUpdateCountryVisible, setIsUpdateCountryVisible] = useState(false);
    const [countryData, setCountryData] = useState([]);
    const [selectedCountryId, setSelectedCountryId] = useState(null);
    const [nextPageUrl, setNextPageUrl] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [deletionCountryId, setDeletionCountryId] = useState(null);



    const fetchCountryData = async (url) => {
        try {
            const response = await axiosInstance.get(url);
            const dataWithDefaults = response.data.results.map(country => ({
                ...country,
                is_active: country.is_active ?? true,
            }));


            setCountryData(dataWithDefaults);
            setNextPageUrl(response.data.next);
        } catch (error) {
            console.error('Error fetching country data:', error);
        }
    };


    useEffect(() => {
        fetchCountryData(`kanban/api/v1/countries/?limit=20&search=${searchQuery}`);
    }, [searchQuery]);



    const toggleAddCountryModal = () => {
        setIsAddCountryVisible(prev => !prev);
    };

    const toggleUpdateCountryModal = (countryId) => {
        setSelectedCountryId(countryId);
        setIsUpdateCountryVisible(prev => !prev);
    };

    const toggleVisibility = async (index, countryId, currentVisibility) => {
        const updatedVisibility = !currentVisibility;

        try {
            const updatedCountryData = [...countryData];
            updatedCountryData[index] = { ...updatedCountryData[index], is_enabled: updatedVisibility };
            setCountryData(updatedCountryData);

            await axiosInstance.patch(`/kanban/api/v1/country_detail/${countryId}/`, {
                is_enabled: updatedVisibility,
            });
        } catch (error) {
            console.error('Error toggling visibility:', error);


            const revertedCountryData = [...countryData];
            revertedCountryData[index] = { ...revertedCountryData[index], is_enabled: !updatedVisibility };
            setCountryData(revertedCountryData);
        }
    };

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop + 100 >= document.documentElement.offsetHeight && nextPageUrl) {
            fetchMoreData();
        }
    };

    const fetchMoreData = async () => {
        if (!nextPageUrl) return;

        try {
            const response = await axiosInstance.get(nextPageUrl);
            const dataWithDefaults = response.data.results.map(country => ({
                ...country,
                is_enabled: country.is_enabled?? true,
            }));

          
            const existingCountryIds = new Set(countryData.map(country => country.id));
            const newCountries = dataWithDefaults.filter(country => !existingCountryIds.has(country.id));

            setCountryData(prevData => [...prevData, ...newCountries]);
            setNextPageUrl(response.data.next);
        } catch (error) {
            console.error('Error fetching more country data:', error);
        }
    };

    useEffect(() => {
        const debouncedHandleScroll = debounce(handleScroll, 200);
        window.addEventListener('scroll', debouncedHandleScroll);
        return () => window.removeEventListener('scroll', debouncedHandleScroll);
    }, [nextPageUrl]);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };
    const handleDelete = async (id) => {
        try {
            const response = await axiosInstance.patch(`kanban/api/v1/country_detail/${deletionCountryId}/`, { is_active: false });
    
            if (response.status === 200) {
                // Refresh the country data after successful deletion
                fetchCountryData(`kanban/api/v1/countries/?limit=20&search=${searchQuery}`);
    
                // Close the modal
                setShowModal(false);
            }
        } catch (error) {
            console.error('Error updating country:', error);
        }
    };
    // const handleDelete = async () => {
    //     try {
    //         // Ensure deletionCountryId is used in the API call
    //         await axiosInstance.patch(`kanban/api/v1/country_detail/${deletionCountryId}/`, { is_active: false });
    
    //         // Update the UI to reflect the deletion
    //         setCountryData(prevData =>
    //             prevData.filter(country => country.id !== deletionCountryId)
    //         );
    
    //         // Close the modal after successful deletion
    //         setShowModal(false);
    //         setDeletionCountryId(null);
    //     } catch (error) {
    //         console.error('Error deleting country:', error);
    //     }
    // };
    

    
    const confirmDelete = (countryId) => {
        setDeletionCountryId(countryId);
        setShowModal(true); 
    };

    const closeModal = () => {
        setShowModal(false); 
    };

    return (
        <div className=" flex flex-col relative ">
            <div className="flex flex-col gap-4  ">
                {/* Search Bar */}
                <div className='flex gap-4 justify-center py-2 '>
                    <div className="relative  ">
                        <span className="absolute inset-y-0 left-0 pl-3 text-gray-400 text-sm flex items-center">
                            <SearchIcon />
                        </span>
                        <input
                            type="text"
                            className="inter-font block w-96 text-[12px] rounded-md pr-12 pl-10 pr-3 py-2.5  border-gray-200 border-none placeholder-gray-400 focus:outline-none tracking-wide"
                            placeholder="Search Country here..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                    </div>
                    <div onClick={toggleAddCountryModal}>
                        <button
                            type="button"
                            className="inter-font bg-black text-white text-[12px] font-bold px-4 py-2 rounded-md hover:bg-gray-900 focus:outline-none focus:bg-gray-900"
                        >
                            Add Country
                        </button>
                    </div>
                </div>


                <div className='h-screen overflow-x-auto '>
                    <table className=' w-[800px] mx-auto'>
                        <thead>
                            <tr className='bg-black pb-4 '>
                                <td className="inter-font whitespace-nowrap px-4 py-3 text-[11px] text-white">
                                    Country
                                </td>
                                <td className="inter-font whitespace-nowrap px-4 py-3 text-[11px] text-white">
                                    Status
                                </td>
                                <td className="inter-font whitespace-nowrap px-4 py-3 text-[11px] text-white ">
                                    Action
                                </td>


                            </tr>
                        </thead>
                        <tbody >
                            {countryData.length > 0 ? (
                                countryData.map((country, index) => (
                                    <tr
                                        key={index}
                                        className="shadow-lg bg-white"
                                        style={{
                                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)', // Adjust the shadow intensity as needed
                                        }}
                                    >
                                        <td className="inter-light flex items-center gap-4 px-4 py-3 text-[12px] text-black tracking-wider">
                                            <div className='flex items-center'>
                                                <img src={country.flag} alt={`${country.name} flag`} className="w-8 h-8 rounded-full" />
                                                <span className="ml-2">{country.name}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div>{country.is_enabled ?
                                                <span className="ml-2 inter-semilight text-green-600 bg-green-100 px-4 py-1 rounded-sm text-[12px]">Active </span>
                                                : <span className="ml-2 inter-semilight text-red-600 bg-red-100 px-3 py-1 rounded text-[12px]">InActive</span>
                                            }</div>
                                        </td>
                                        <td className="inter-light px-4 py-3 text-[12px] text-black tracking-wider">
                                            <div className='flex gap-2'>
                                                <div onClick={() => toggleVisibility(index, country.id, country.is_enabled)}>
                                                    {country.is_enabled ? (
                                                        <VisibilityIcon style={{ fontSize: '18px', cursor: 'pointer' }} />
                                                    ) : (
                                                        <VisibilityOffIcon style={{ fontSize: '18px', cursor: 'pointer' }} />
                                                    )}
                                                </div>
                                                <div onClick={() => toggleUpdateCountryModal(country.id)}>
                                                    <EditIcon style={{ fontSize: '18px', cursor: 'pointer' }} />
                                                </div>
                                                <div onClick={() => confirmDelete(country.id)}>
                                                    <DeleteIcon style={{ fontSize: '18px', cursor: 'pointer' }} />
                                                </div>
                                            </div>
                                        </td>

                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3" className="text-center py-4 text-gray-500">
                                        No countries available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Add Country Modal */}
            <AnimatePresence>
                {isAddCountryVisible && <AddCountry onClose={toggleAddCountryModal} setIsAddCountryVisible={setIsAddCountryVisible} fetchCountryData={fetchCountryData} />}
            </AnimatePresence>

            {/* Update Country Modal */}
            <AnimatePresence>
                {isUpdateCountryVisible && <UpdateCountry countryId={selectedCountryId} onClose={toggleUpdateCountryModal} setIsUpdateCountryVisible={setIsUpdateCountryVisible} fetchCountryData={fetchCountryData} />}
            </AnimatePresence>
            {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-8 rounded-md shadow-md border border-black border-2 m-1">
                        <div className="flex">
                            <div><DeleteForeverIcon style={{ color: 'red' }} /></div>
                            <div className="inter-light pb-6 mt-1 text-[15px]">
                                Are you sure you want to delete this country?
                            </div>
                        </div>
                        <div className="flex justify-end gap-4">
                            <button onClick={handleDelete} className="bg-black text-white font-bold py-1 px-8 text-[13px] rounded-sm inter-light">
                                Delete
                            </button>
                            <button onClick={closeModal} className="bg-black text-white font-bold py-1 px-8 text-[13px] rounded-sm inter-light">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CountryListingPage;
















