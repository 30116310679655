import React, { useState, useEffect } from "react";
import axiosInstance from "../axiosInstance/axiosInstance";

const ImageUploadModal = ({ open, onClose , onUploadSuccess }) => {
  const [bannerUrl, setBannerUrl] = useState("");
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [educationLevels, setEducationLevels] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState("");

  useEffect(() => {
    const fetchEducationLevels = async () => {
      try {
        const response = await axiosInstance.get("/account/api/v1/pages-dropdown/");
        setEducationLevels(response.data); // Assuming the API returns an array
      } catch (error) {
        console.error("Error fetching education levels:", error);
      }
    };

    fetchEducationLevels();
  }, []);

  useEffect(() => {
    if (open === true) {
      setBannerUrl("");
      setUploadedImageUrl(null);
      setUploading(false);
    }
  }, [open]);

  // Function to handle image selection and upload
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploading(true); // Show loading state

    try {
      const formData = new FormData();
      formData.append("file", file);

      // Upload the image immediately
      const uploadResponse = await axiosInstance.post(
        "home/api/v1/upload-file/",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      console.log("Upload response:", uploadResponse.data); // Debugging: Check the response

      const imageUrl = uploadResponse.data.image_url; // Extract the correct image URL
      if (!imageUrl) {
        throw new Error("Image URL not found in response");
      }

      setUploadedImageUrl(imageUrl); // Store image URL in state
      console.log("Image uploaded successfully:", imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
      alert("Image upload failed");
    } finally {
      setUploading(false); // Hide loading state
    }
  };

  // Function to handle banner URL input
  const handleBannerUrlChange = (e) => {
    setBannerUrl(e.target.value);
  };

  // Function to submit the final form
  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("Submitting with Image URL:", uploadedImageUrl);
    console.log("Selected Education Level ID:", selectedLevel);
    console.log("Banner URL:", bannerUrl);

    if (!bannerUrl || !uploadedImageUrl || !selectedLevel) {
        alert("Please provide a Banner URL, upload an image, and select an education level.");
        return;
    }

    try {
        const payload = {
            web_banner_img: uploadedImageUrl, // Image URL from upload
            banner_url: bannerUrl, // URL entered in the input field
            page: selectedLevel, // Selected education level ID
        };

        console.log("Payload being sent:", payload);

        const bannerResponse = await axiosInstance.post(
            "account/api/v1/home-banners/",
            payload
        );
        if (bannerResponse.status === 201) {
          alert("Banner uploaded successfully");
          onUploadSuccess(); // Call the function passed from the parent to re-fetch the banners
          onClose(); // Close modal after success
        }

        console.log("Banner uploaded successfully:", bannerResponse.data);
        // alert("Banner uploaded successfully");

        // Optionally reset form fields after successful submission
        setBannerUrl("");
        setUploadedImageUrl(null);
        setSelectedLevel("");

        onClose(); // Close the modal after submission
    }

     catch (error) {
        console.error("Error submitting banner:", error);
        alert("Failed to upload banner");
    }
};


  return (
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors ${open ? "visible bg-black/20" : "invisible"
        }`}
    >
      <div
        className={`bg-white rounded-xl shadow p-6 transition-all ${open ? "scale-100 opacity-100" : "scale-125"
          }`}
      >
        <form onSubmit={handleSubmit}>
          <div>
            <label className="block text-[13px] inter-light inter-font text-gray-700 py-2">Select Image:</label>

            <div className="flex items-center justify-center w-80">
              <div className="flex flex-col items-center justify-center bg-white w-full h-32 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-black relative">
                {uploadedImageUrl ? (
                  <img
                    src={uploadedImageUrl}
                    alt="Uploaded Preview"
                    className="w-full h-full object-contain rounded-lg"
                  />
                ) : (
                  <div className="text-gray-500 text-center">
                    <img src="Camera.svg" alt="cameraicon" />
                  </div>
                )}

                {/* Input element takes up the full area of the div */}
                <input
                  type="file"
                  onChange={handleImageChange}
                  className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                />
              </div>
            </div>
          </div>
          <div>
            <label className="block text-[13px] inter-light inter-font text-gray-700 mt-4">
              Banner URL
            </label>
            <input
              id="URL"
              type="text"
              value={bannerUrl}
              onChange={handleBannerUrlChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-black focus:border-black sm:text-sm"
            />
          </div>
          <div>
            <select
              id="education-level"
              value={selectedLevel}
              onChange={(e) => setSelectedLevel(parseInt(e.target.value, 10))}
              className="block w-full text-[15px] mt-2 appearance-none bg-black text-white border border-gray-300 px-4 py-2 pr-8 rounded-lg shadow-sm "
            >
                      <option className="inter-light ">Select page</option>
              {educationLevels.map((level) => (
                <option key={level.id} value={level.id}>
                 <div className="inter-light  ">{level.name}</div> 
                </option>
              ))}
            </select>
          </div>

          {/* {uploading && <p>Uploading image...</p>}
          {uploadedImageUrl && <p>Image uploaded successfully!</p>} */}

          <div className="flex justify-end gap-4 mt-4">
            <button
              className="bg-green-600 inter-light text-white rounded-[5px] py-2 px-6"
              type="submit"
              onClick={onClose}
            >
              Save
            </button>
            <button
              className="bg-red-600 inter-light text-white rounded-[5px] py-2 px-6"
              type="button"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </form>
      </div>
      <div></div>
    </div>
  );
};

export default ImageUploadModal;
